import React, { useEffect, useState, useContext } from "react"
import { navigate } from "gatsby"
import { Flex, Box, Text, Image } from "rebass"
import qs from "qs"

import { useAuth } from "../../context/auth"
import { AccountContext } from "../../context/account"
import LoginLayout from "../../components/login-layout"
import SignupForm from "../../components/signup-form"
import Spinner from "../../components/spinner"

import MedusaClient from "../../services/api"
import { trackSignUp } from "../../services/analytics"

const SignupPage = () => {
  const [loading, setLoading] = useState(true)
  const [googleRes, setGoogleRes] = useState({})
  const [error, setError] = useState()

  const auth = useAuth()
  const { handleCreateAccount } = useContext(AccountContext)

  useEffect(() => {
    const search = window.location.search
    const res = qs.parse(search)
    setLoading(false)
    setGoogleRes(res)
  }, [])

  const handleLogin = async values => {
    setLoading(true)

    return await auth
      .handleSignup(
        "google",
        {
          token: googleRes.token,
          first_name: values.first_name,
          last_name: values.last_name
        },
        {
          company: values.company,
          role: values.role,
          industry: values.industry
        }
      )
      .then(async () => {
        if (
          googleRes.next === "cli" &&
          "cli_tok" in googleRes &&
          "req_tok" in googleRes
        ) {
          await MedusaClient.cliAuth.authenticate(
            googleRes.cli_tok,
            googleRes.req_tok
          )

          navigate(`/cli-login/done`)
        } else {
          await handleCreateAccount({
            name: values.name,
            billing_email: values.email
          })

          navigate("/a")
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <LoginLayout pt={3} title={"Sign Up"}>
      <Text mb={4} fontWeight="bold" fontSize={4}>
        Sign Up
      </Text>
      {loading ? (
        <Flex justifyContent="center">
          <Spinner dark width="20px" height="20px" />
        </Flex>
      ) : (
        <SignupForm
          initValues={{
            email: googleRes.email,
            first_name: googleRes.given_name,
            last_name: googleRes.family_name
          }}
          disabledFields={["email"]}
          hiddenFields={["password"]}
          onSignup={handleLogin}
        />
      )}
    </LoginLayout>
  )
}

export default SignupPage
