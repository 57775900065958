import React, { useEffect, useState, useContext } from "react"
import { Flex, Box, Text } from "rebass"
import PropTypes from "prop-types"
import { useForm } from "react-hook-form"

import InputField from "../input"
import Button from "../button"
import Select from "../select"

const ValidatedInput = props => {
  return (
    <Flex sx={{ flexDirection: "column" }}>
      <InputField
        {...props}
        ref={props.refProp}
        mb={3}
        boldLabel={true}
        name={props.id}
        disabled={props.disabledFields.includes(props.id)}
        placeholder={props.placeholder}
      />
      {props.errors.includes(props.id) && (
        <Text sx={{ color: "red", fontSize: "12px", fontWeight: 400, mt: -2 }}>
          {props.errorMessage}
        </Text>
      )}
    </Flex>
  )
}

const SignupForm = ({ onSignup, initValues, disabledFields, hiddenFields }) => {
  const [errors, setErrors] = useState([])

  const { register, setValue, handleSubmit } = useForm({
    shouldUnregister: false,
  })

  useEffect(() => {
    for (const [key, value] of Object.entries(initValues)) {
      setValue(key, value)
    }
  }, [initValues])

  const onSubmit = data => {
    const errs = []

    if (data["email"] == "") errs.push("email")
    if (data["first_name"] == "") errs.push("first_name")
    if (data["last_name"] == "") errs.push("last_name")
    if (data["name"] == "") errs.push("name")
    if (data["password" == ""]) errs.push("password")
    if (!data["password"].match(/(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}/))
      errs.push("password")

    setErrors(errs)

    if (errs.length == 0) {
      onSignup(data)
    }
  }

  const props = { refProp: register, errors, disabledFields }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ValidatedInput
        label="Account name"
        id="name"
        placeholder="Acme Inc."
        errorMessage="Missing name"
        {...props}
      />

      <ValidatedInput
        label="Email"
        id="email"
        placeholder="lebron@james.com"
        errorMessage="Missing email"
        {...props}
      />
      <Flex justifyContent="space-between" mb={3}>
        <ValidatedInput
          label="First Name"
          placeholder="Lebron"
          id="first_name"
          errorMessage="Missing first name"
          {...props}
        />
        <ValidatedInput
          label="Last Name"
          placeholder="James"
          id="last_name"
          errorMessage="Missing last name"
          {...props}
        />
      </Flex>
      {!hiddenFields.includes("password") && (
        <ValidatedInput
          placeholder="SuperSecret123!"
          label="Password"
          type="password"
          id="password"
          errorMessage="Password must be at least 8 characters long, contain at least one number, letter and special character"
          {...props}
        />
      )}
      <Select
        disabled={disabledFields.includes("role")}
        mb={3}
        label="What is your role"
        name="role"
        ref={register}
        boldLabel={true}
        options={[{ value: "Software Developer" }]}
        placeholder="Select your role"
        selectStyle={{ padding: "8px", flex: "100% 0 0 !important" }}
      />
      <Select
        disabled={disabledFields.includes("industry")}
        label="What your company does"
        name="industry"
        ref={register}
        boldLabel={true}
        options={[{ value: "Software Development" }]}
        placeholder="Select your industry"
        selectStyle={{ padding: "8px", flex: "100% 0 0 !important" }}
      />
      <Button type="submit" variant={"cta"} mt={4} width={1}>
        Confirm & Create Account
      </Button>
    </form>
  )
}

SignupForm.defaultProps = {
  initValues: {},
  disabledFields: [],
  hiddenFields: [],
}

SignupForm.propTypes = {
  onSignup: PropTypes.func.isRequired,
  initValues: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    company: PropTypes.string,
  }),
  disabledFields: PropTypes.arrayOf(PropTypes.string),
  hiddenFields: PropTypes.arrayOf(PropTypes.string),
}

export default SignupForm
